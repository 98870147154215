.customLook .tagify__tag:only-of-type .tagify__tag__removeBtn {
    display: block !important;
}
article{
    width: 100%;
    //max-width: 700px;
    border: 1px solid #ddd;
    margin-top: 5px;
    height : auto;
    min-height: 70px;
    overflow: auto;
    padding: 5px;
}
.tag {
    background: #61171e;
    padding: 1px 3px !important;
    font-size: 10px;
    margin: 1px 2px !important;
    border-radius: 3px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.customLook{
    --tag-bg                  : #0052BF;
    --tag-hover               : #CE0078;
    --tag-text-color          : #FFF;
    --tags-border-color       : silver;
    --tag-text-color--edit    : #111;
    --tag-remove-bg           : var(--tag-hover);
    --tag-pad                 : .6em 1em;
    --tag-inset-shadow-size   : 1.3em;
    --tag-remove-btn-bg--hover: black;

    display: inline-block;
    min-width: 0;
    border: none;
}

.customLook .tagify__tag{
    margin-top: 0;
}

.customLook .tagify__tag > div{
    // border-radius: 25px;
}

.customLook .tagify__tag:only-of-type .tagify__tag__removeBtn{
    display: none;
}

.customLook .tagify__tag__removeBtn{
    opacity: 0;
    transform: translateX(-6px) scale(.5);
    margin-left: -3ch;
    transition: .12s;
}

.customLook .tagify__tag:hover .tagify__tag__removeBtn{
    transform: none;
    opacity: 1;
    margin-left: -1ch;
}

.customLook + button{
    color: #0052BF;
    font: bold 1.4em/1.65 Arial;
    border: 0;
    background: none;
    box-shadow: 0 0 0 2px inset currentColor;
    border-radius: 50%;
    width: 1.65em;
    height: 1.65em;
    cursor: pointer;
    outline: none;
    transition: .1s ease-out;
    margin: 0 0 0 5px;
    vertical-align: top;
}

// .customLook + button:hover{
// box-shadow: 0 0 0 5px inset currentColor;
// }

.customLook .tagify__input{
display: none;
}

#tags_filter {
    display: flex;
    justify-content: flex-end;
}

.btn-info {
    --bs-btn-color: #ffffff;
    --bs-btn-bg: #5c6bc0;
    --bs-btn-border-color: #5c6bc0;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #3949ab;
    --bs-btn-hover-border-color: #3949ab;
    --bs-btn-focus-shadow-rgb: 11, 172, 204;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #3949ab;
    --bs-btn-active-border-color: #3949ab;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #000;
    --bs-btn-disabled-bg: #5c6bc0;
    --bs-btn-disabled-border-color: #5c6bc0;
}

.tagify__tag>div>* {
    white-space: unset !important;
}
